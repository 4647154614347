// extracted by mini-css-extract-plugin
export var anotherFontSize = "banner-module--anotherFontSize--mixmz";
export var btn = "banner-module--btn--n324y";
export var btnBlock = "banner-module--btnBlock--ABAo7";
export var container = "banner-module--container---Bl6J";
export var darkColor = "banner-module--darkColor--bNzUj";
export var head = "banner-module--head--2v3Hn";
export var hideMobBlock = "banner-module--hideMobBlock--fZTIo";
export var hippo = "banner-module--hippo--syrbi";
export var img = "banner-module--img--1tl5B";
export var imgFriday = "banner-module--imgFriday--KSwWT";
export var imgHippo = "banner-module--imgHippo--pugyR";
export var imgRostics = "banner-module--imgRostics--mCtYp";
export var mediaD = "banner-module--mediaD--2qC+r";
export var mobBtn = "banner-module--mobBtn--wDZb4";
export var notpkwBanner = "banner-module--notpkwBanner--pbdkE";
export var pkwBonus = "banner-module--pkwBonus--mt+to";
export var red = "banner-module--red--N7-UF";
export var rosticsBg = "banner-module--rosticsBg--+KU8+";
export var secondContainer = "banner-module--secondContainer--tGAmj";
export var subText = "banner-module--subText--pEPgY";
export var subTextFriday = "banner-module--subTextFriday--208js";
export var text = "banner-module--text--VdQZF";
export var vkEducation = "banner-module--vkEducation--XO7f3";